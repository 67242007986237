import React from "react";

const Footer = () => {
	return (
		<footer className="text-center mt-20">
			<p>
				Made with{" "}
				<span role="img" aria-label="heart">
					❤️
				</span>{" "}
				by PG
			</p>
		</footer>
	);
};

export default Footer;

// Sample channelVideoItem:
// videoId, duration,viewCount,likeCount,dislikeCount,publishedAt,title,commentCount,categoryId,
// "7ESeQBeikKs,PT15M34S,88123533,3651334,0,2024-01-13T17:00:00Z,Protect $500,000 Keep It!,66203,24"

export const filterandSortChannelVideos = (channelVideos, filters) => {
	const filteredVideos = channelVideos.filter((videoString) => {
		const video = parseVideoString(videoString);

		const { duration, viewCount, likeCount, dislikeCount } = video;

		const searchTermMatch = matchSearchTerm(video, filters.searchTerm);
		//console.log(searchTermMatch);

		const dateRangeMatch = matchDateRange(
			video,
			filters.publishedDate.from,
			filters.publishedDate.to
		);
		//console.log(dateRangeMatch);

		const viewCountMatch = matchRange(viewCount, filters.viewCount);
		//console.log(viewCountMatch);

		const likeCountMatch = matchRange(likeCount, filters.likeCount);
		//console.log(likeCountMatch);

		const dislikeCountMatch = matchRange(
			dislikeCount,
			filters.dislikeCount
		);
		//console.log(dislikeCountMatch);

		const durationMatch = matchDuration(duration, filters.duration);
		//console.log(durationMatch);

		return (
			searchTermMatch &&
			dateRangeMatch &&
			viewCountMatch &&
			likeCountMatch &&
			dislikeCountMatch &&
			durationMatch
		);
	});

	sortVideos(filteredVideos, filters.sortBy);

	return filteredVideos;
};

const sortVideos = (filteredVideos, sortingAlgorithm) => {
	switch (sortingAlgorithm) {
		case "Views [High -> Low]":
			filteredVideos.sort((a, b) => b.split(",")[2] - a.split(",")[2]);
			break;
		case "Views [Low -> High]":
			filteredVideos.sort((a, b) => a.split(",")[2] - b.split(",")[2]);
			break;
		case "Likes [High -> Low]":
			filteredVideos.sort((a, b) => b.split(",")[3] - a.split(",")[3]);
			break;
		case "Likes [Low -> High]":
			filteredVideos.sort((a, b) => a.split(",")[3] - b.split(",")[3]);
			break;
		case "Dislikes [High -> Low]":
			filteredVideos.sort((a, b) => b.split(",")[4] - a.split(",")[4]);
			break;
		case "Dislikes [Low -> High]":
			filteredVideos.sort((a, b) => a.split(",")[4] - b.split(",")[4]);
			break;
		case "Comments [High -> Low]":
			filteredVideos.sort((a, b) => b.split(",")[7] - a.split(",")[7]);
			break;
		case "Comments [Low -> High]":
			filteredVideos.sort((a, b) => a.split(",")[7] - b.split(",")[7]);
			break;

		case "Like/Dislike Ratio [High -> Low]":
			filteredVideos.sort((a, b) => {
				const ratioA =
					a.split(",")[3] / (a.split(",")[3] + a.split(",")[4]);
				const ratioB =
					b.split(",")[3] / (b.split(",")[3] + b.split(",")[4]);
				return ratioB - ratioA;
			});
			break;

		case "Like/Dislike Ratio [Low -> High]":
			filteredVideos.sort((a, b) => {
				const ratioA =
					a.split(",")[3] / (a.split(",")[3] + a.split(",")[4]);
				const ratioB =
					b.split(",")[3] / (b.split(",")[3] + b.split(",")[4]);
				return ratioA - ratioB;
			});
			break;

		case "Date [High -> Low]":
			filteredVideos.sort(
				(a, b) => new Date(b.split(",")[5]) - new Date(a.split(",")[5])
			);
			break;
		case "Date [Low -> High]":
			filteredVideos.sort(
				(a, b) => new Date(a.split(",")[5]) - new Date(b.split(",")[5])
			);
			break;
		default:
			filteredVideos.sort((a, b) => b.split(",")[3] - a.split(",")[3]);
			break;
	}
};

const parseVideoString = (videoString) => {
	const [
		videoId,
		duration,
		viewCount,
		likeCount,
		dislikeCount,
		publishedAt,
		title,
		commentCount,
		categoryId,
	] = videoString.split(",");

	return {
		videoId,
		duration,
		viewCount,
		likeCount,
		dislikeCount,
		publishedAt,
		title,
		commentCount,
		categoryId,
	};
};

const matchSearchTerm = (video, searchTerm) => {
	const { videoId, title } = video;
	const searchTermLower = searchTerm.toLowerCase();
	return (
		title.toLowerCase().includes(searchTermLower) ||
		videoId.toLowerCase().includes(searchTermLower)
	);
};

const matchDateRange = (video, fromDate, toDate) => {
	const publishedDate = new Date(video.publishedAt);
	//console.log(publishedDate);
	const from = new Date(fromDate);

	const to = new Date(toDate);

	return publishedDate >= from && publishedDate <= to;
};

const matchRange = (value, range) => {
	const intValue = parseInt(value);

	return intValue >= parseInt(range.from) && intValue <= parseInt(range.to);
};

const matchDuration = (duration, durationRange) => {
	const durationInSeconds = parseISODuration(duration);

	const fromSeconds = parseDurationToSeconds(durationRange.from);

	const toSeconds = parseDurationToSeconds(durationRange.to);
	return durationInSeconds >= fromSeconds && durationInSeconds <= toSeconds;
};

const parseISODuration = (duration) => {
	const match = duration.match(
		/^P(?:(\d+)D)?T(?:(\d+H)?(?:(\d+M))?(?:(\d+S))?)?$/
	);
	const days = match[1] || 0;
	const hours = (match[2] || "0H").slice(0, -1);
	const minutes = (match[3] || "0M").slice(0, -1);
	const seconds = (match[4] || "0S").slice(0, -1);

	return (
		parseInt(days * 86400) +
		parseInt(hours * 3600) +
		parseInt(minutes * 60) +
		parseInt(seconds)
	);
};

const parseDurationToSeconds = (duration) => {
	const [hours, minutes, seconds] = duration.split(":").map(Number);
	return hours * 3600 + minutes * 60 + seconds;
};

import React from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";

const SupportPopup = ({ toggleSupportPopup }) => {
	return (
		<>
			<div className="fixed inset-0 flex items-center justify-center border">
				<div className="bg-gray-100 p-6 rounded-lg w-3/4 h-5/6 relative ">
					<button
						className="absolute top-0 right-0"
						onClick={() => toggleSupportPopup()}
					>
						<XCircleIcon className="h-12 w-12 text-gray-800" />
					</button>
					<div className="text-3xl font-bold mb-6">
						<p> Hi! </p> <br />
						<p>
							I really appreciate you taking the time to support
							this project. 🙌 🙌 The google form is already
							filled to the brim with channel suggestions. 😄
						</p>{" "}
						<br />
						<p>
							{" "}
							Unfortunately, I can only onboard few channels per
							day 😓
						</p>{" "}
						<br />
						<p>
							{" "}
							So while suggesting a channel, you need to pay a
							small fee. This helps me prioritize channels and
							sustain the project.
						</p>{" "}
						<br />
						<p> Thank you for the contribution!! 🥳 🥳</p> <br />
					</div>
					<a
						type="button"
						className="text-white bg-[#050708] hover:bg-[#050708]/80 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mb-2"
						href="https://donate.stripe.com/cN228AaEG1O9gogeUX"
						target="_blank"
						rel="noopener noreferrer"
					>
						{/* <img
							src={`stripe.png`}
							alt="stripe"
							className="w-15 h-5 me-2 -ms-1"
						/> */}
						Support with Stripe
					</a>
				</div>
			</div>
		</>
	);
};

export default SupportPopup;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Channel from "./pages/Channel";
import FAQ from "./pages/FAQ";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Channel />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/channel/:channelName" element={<Channel />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;

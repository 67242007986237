import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const FAQ = () => {
	return (
		<>
			<Navbar />

			<div className="container mx-auto py-8 px-4">
				<h1 className="text-2xl font-bold mb-6">FAQ</h1>
				<div className="text-lg">
					<h4>Q. What does this site do?</h4>
					<p>
						A. This site allows you to apply advanced filters for
						videos on YouTube.
					</p>

					<h4>Q. How often is this site updated?</h4>
					<p>
						A. Youtube restricts to updating 10,000 videos per day,
						so we cover 10-20 channels daily.
					</p>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default FAQ;

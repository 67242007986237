import React, { useState, useEffect } from "react";
import supabase from "../supabase";

import ChannelInfo from "../components/ChannelInfo";
import Navbar from "../components/Navbar";
import Filters from "../components/Filters";
import Footer from "../components/Footer";
import Videos from "../components/Videos";
import { useParams } from "react-router-dom";
import { filterandSortChannelVideos } from "../utils/filterUtils";

const setRandomChannel = async () => {
	try {
		// Replace 'your_table_name' with the name of your table
		const { data, error } = await supabase
			.from("channels")
			.select("channelTitle");

		if (error) {
			throw error;
		}

		if (data) {
			const channels = data.map((row) => row.channelTitle);

			let randomChannel =
				channels[Math.floor(Math.random() * channels.length)];
			//console.log(randomChannel);
			return randomChannel;
		}

		return "CGPGrey";
	} catch (error) {
		console.error("Error fetching primary key values:", error.message);
	}
};

function Channel() {
	let { channelName } = useParams();
	const [currentChannel, setCurrentChannel] = useState(null);

	useEffect(() => {
		const fetchChannel = async () => {
			try {
				const fetchedChannel =
					channelName || (await setRandomChannel()) || "CGPGrey";
				setCurrentChannel(fetchedChannel);
			} catch (error) {
				console.error("Error fetching channel:", error);
			}
		};

		fetchChannel();
	}, [channelName]);

	const [filters, setFilters] = useState({
		searchTerm: "",
		sortBy: "Views [High -> Low]",
		publishedDate: { from: "2000/01/31", to: "2050/01/31" },
		viewCount: { from: "0", to: "100000000000" },
		likeCount: { from: "0", to: "100000000000" },
		commentCount: { from: "0", to: "100000000000" },
		dislikeCount: { from: "0", to: "100000000000" },
		duration: { from: "00:00:00", to: "99:59:59" },
	});

	const [channelVideos, setChannelVideos] = useState([]);
	const [channelInfo, setChannelInfo] = useState({});

	const [filteredChannelVideos, setFilteredChannelVideos] = useState([]);

	useEffect(() => {
		const filtervids = filterandSortChannelVideos(channelVideos, filters);

		setFilteredChannelVideos(filtervids);
	}, [filters, channelVideos]);

	useEffect(() => {
		async function fetchChannelVideos() {
			try {
				const { data, error } = await supabase
					.from("channels")
					.select()
					.eq("channelTitle", currentChannel);

				if (error) {
					throw error;
				}

				if (data) {
					//console.log(data);

					const newChannelInfo = {
						title: data[0].channelTitle,
						thumbnail: data[0].thumbnail,
						subscriberCount: data[0].subscriberCount,
						videoCount: data[0].videoCount,
						lastUpdatedAt: data[0].lastUpdatedAt,
					};
					setChannelVideos(data[0].videos);
					setChannelInfo(newChannelInfo);
				}
			} catch (error) {
				console.error(
					"Error fetching primary key values:",
					error.message
				);
			}
		}

		fetchChannelVideos();
	}, [currentChannel]);

	return (
		<>
			<Navbar />
			<ChannelInfo channelInfo={channelInfo} />
			<div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-10">
				<div className="col-span-2">
					<Filters filters={filters} setFilters={setFilters} />
				</div>
				<div className="col-span-8">
					<Videos channelVideos={filteredChannelVideos} />
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Channel;

import React from "react";

const VideoBox = ({ videoInfoString }) => {
	// Sample videoInfoString:
	// videoId, duration,viewCount,likeCount,dislikeCount,publishedAt,title,commentCount,categoryId,
	// "7ESeQBeikKs,PT15M34S,88123533,3651334,0,2024-01-13T17:00:00Z,Protect $500,000 Keep It!,66203,24"

	//const thumbnail = "https://i.ytimg.com/vi/" + videoInfo[0] + "/default.jpg";

	const [
		videoId,
		duration,
		views,
		likeCount,
		dislikeCount,
		publishedAt,
		title,
		commentCount,
		// eslint-disable-next-line
		categoryId,
	] = videoInfoString.split(",");

	function formatDuration(duration) {
		const match = duration.match(
			/^P(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/
		);

		const days = parseInt(match[1]) || 0;
		const hours = parseInt(match[2]) || 0;
		const minutes = parseInt(match[3]) || 0;
		const seconds = parseInt(match[4]) || 0;

		const formattedDuration = [days, hours, minutes, seconds]
			.map((unit) => unit.toString().padStart(2, "0"))
			.join(":");

		return formattedDuration;
	}

	const formattedDuration = formatDuration(duration);

	return (
		<div className="max-w-xl mx-auto overflow-hidden bg-white shadow-lg rounded-lg">
			<a
				href={`https://www.youtube.com/watch?v=${videoId}`}
				target="_blank"
				rel="noreferrer"
			>
				<img
					className="w-full h-48 object-cover"
					src={`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`}
					alt={title}
				/>
			</a>
			<div className="p-4">
				<h2 className="text-xl font-semibold text-gray-800">{title}</h2>
				<div className="mt-2">
					<span className="text-sm text-gray-600">{publishedAt}</span>
				</div>
				<div className="mt-2">
					<span className="text-sm text-gray-600">{views} views</span>
				</div>
				<div className="mt-2">
					<span className="text-sm text-gray-600">
						{formattedDuration}
					</span>
				</div>
				<div className="mt-2">
					<span className="text-sm text-gray-600">
						{likeCount} likes
					</span>
				</div>
				<div className="mt-2">
					<span className="text-sm text-gray-600">
						{dislikeCount} dislikes
					</span>
				</div>

				<div className="mt-2">
					<span className="text-sm text-gray-600">
						{commentCount} comments
					</span>
				</div>
			</div>
		</div>
	);
};

export default VideoBox;

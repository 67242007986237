import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../supabase";
import { Link } from "react-router-dom";
import SupportPopup from "./SupportPopup";

const Navbar = () => {
	const [searchInput, setSearchInput] = useState("");
	const [showDropdown, setShowDropdown] = useState(false);
	const [randomWords, setRandomWords] = useState([]);
	const [isSupportPopupOpen, setIsSupportPopupOpen] = useState(false);
	const navigate = useNavigate();

	const [primaryKeyValues, setPrimaryKeyValues] = useState([]);

	useEffect(() => {
		// Event listener to hide dropdown when user clicks outside
		const handleOutsideClick = (event) => {
			if (searchInput.trim().split(/\s+/).length === 1 && showDropdown) {
				if (!event.target.closest(".dropdown-container")) {
					setShowDropdown(false);
				}
			}
		};

		// Attach the event listener when the component mounts
		document.addEventListener("click", handleOutsideClick);

		// Cleanup: Remove the event listener when the component unmounts
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, [searchInput, showDropdown]);

	useEffect(() => {
		async function fetchPrimaryKeyValues() {
			try {
				// Replace 'your_table_name' with the name of your table
				const { data, error } = await supabase
					.from("channels")
					.select("channelTitle");

				if (error) {
					throw error;
				}

				if (data) {
					// Extract primary key values from the response
					const values = data.map((row) => row.channelTitle);
					setPrimaryKeyValues(values);
				}
			} catch (error) {
				console.error(
					"Error fetching primary key values:",
					error.message
				);
			}
		}

		fetchPrimaryKeyValues();
	}, []);

	function stringMatchingRank(array, input) {
		// Filter the array based on the string matching rank of the input

		const filteredArray = array
			.filter((item) => item.toLowerCase().includes(input.toLowerCase()))
			.sort((a, b) => {
				const rankA = a.toLowerCase().indexOf(input.toLowerCase()); // Calculate rank for string a
				const rankB = b.toLowerCase().indexOf(input.toLowerCase()); // Calculate rank for string b
				return rankA - rankB; // Sort based on rank
			});

		const remainingCount = 5 - filteredArray.length;
		if (remainingCount > 0) {
			const remainingElements = array.filter(
				(item) => !filteredArray.includes(item)
			);
			const elementsToAdd = remainingElements.slice(0, remainingCount);
			filteredArray.push(...elementsToAdd);
		}

		// Return the top 5 elements
		return filteredArray.slice(0, 5);
	}

	// Function to handle input change
	const handleInputChange = (e) => {
		const inputValue = e.target.value;
		setSearchInput(inputValue);

		// Display dropdown if the input length is one word
		setShowDropdown(
			inputValue.trim().split(/\s+/).length === 1 && inputValue.length > 0
		);

		// Generate random words when one word is typed
		if (inputValue.trim().split(/\s+/).length === 1) {
			setRandomWords(stringMatchingRank(primaryKeyValues, inputValue));
		}
	};

	// Function to handle word click and redirect
	const handleWordClick = (name) => {
		setSearchInput("");
		navigate(`/channel/${name}`);
	};

	const toggleSupportPopup = () => {
		setIsSupportPopupOpen(!isSupportPopupOpen);
	};

	return (
		<nav className="bg-blue-500 w-full z-20 top-0 start-0 border-b p-4">
			<div className="container mx-auto flex items-center">
				<div className="text-white text-2xl font-bold flex items-center">
					<Link to="/" className="flex items-center">
						<img
							src="/icon.svg"
							alt="Your Brand Logo"
							className="h-10 w-auto mr-2"
						/>
						<span>TubeSearch</span>
					</Link>
				</div>
				<div className="relative w-1/2 max-w-3xl px-6 mx-auto">
					<div className="absolute top-0 left-0 flex items-center h-10 pl-10 mt-1">
						<svg
							className="w-4 h-4 text-gray-600 fill-current"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
						>
							<path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
						</svg>
					</div>
					<input
						id="search-toggle"
						type="search"
						placeholder="Enter search term"
						className="block w-full py-3 pl-12 pr-4 font-bold text-gray-700 rounded-lg bg-brand-white focus:outline-none focus:bg-white focus:shadow"
						value={searchInput}
						onChange={handleInputChange}
					/>
					{showDropdown && (
						<div className="absolute bg-white mt-1 p-2 rounded-md shadow-md">
							{randomWords.map((word, index) => (
								<p
									key={index}
									onClick={() => handleWordClick(word)}
									className="border-b py-1 cursor-pointer"
								>
									{word}
								</p>
							))}
						</div>
					)}
				</div>

				<div className="flex items-center space-x-4 mx-4">
					<Link to="/faq" className="text-white">
						FAQ
					</Link>
				</div>

				{/* <button
					type="button"
					className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 flex flex-row focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
					onClick={() => toggleSupportPopup()}
				>
					<svg
						viewBox="0 0 24 24"
						width="24"
						height="24"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						{" "}
						<path d="M18 8h1a4 4 0 0 1 0 8h-1" />{" "}
						<path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />{" "}
						<line x1="6" y1="1" x2="6" y2="4" />{" "}
						<line x1="10" y1="1" x2="10" y2="4" />{" "}
						<line x1="14" y1="1" x2="14" y2="4" />
					</svg>
					<span className="mx-2">Suggest & Support</span>
				</button> */}

				<div className="flex items-center space-x-4 mx-4">
					<a
						href="https://forms.gle/DuaNvQPrRuBkMZMy5"
						className="text-white"
						target="_blank"
						rel="noreferrer"
					>
						Recommend a channel
					</a>
				</div>
			</div>
			{isSupportPopupOpen && (
				<SupportPopup toggleSupportPopup={toggleSupportPopup} />
			)}
		</nav>
	);
};

export default Navbar;

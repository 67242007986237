import React from "react";

const ChannelInfo = ({ channelInfo }) => {
	//console.log(channelInfo);
	return (
		<div
			id="channel-header-container"
			className="p-4 flex flex-row justify-start items-center px-4"
		>
			<img
				id="avatar"
				className="rounded-full"
				src={channelInfo.thumbnail}
				alt="Channel Avatar"
			/>
			<div id="inner-header-container" className="ml-4">
				<div id="meta" className="mb-2">
					<h1 className="text-lg font-bold">{channelInfo.title}</h1>
					<p className="text-gray-500">
						{channelInfo.subscriberCount} subscribers •{" "}
						{channelInfo.videoCount} videos •
					</p>
					<p className="text-gray-500">
						last data update - {channelInfo.lastUpdatedAt}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ChannelInfo;

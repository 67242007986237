import React from "react";
import VideoBox from "./VideoBox";

const Videos = ({ channelVideos }) => {
	//console.log(channelVideos);

	return (
		<div className="border px-5 py-5 mx-2 my-2">
			<ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 ml-4">
				{channelVideos.map((video, index) => (
					<li key={index} className="text-blue-500">
						<VideoBox videoInfoString={video} />
					</li>
				))}
			</ul>
		</div>
	);
};

export default Videos;

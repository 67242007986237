import React from "react";

const Filters = ({ filters, setFilters }) => {
    const handleInputChange = (field, value) => {
        setFilters({
            ...filters,
            [field]: value,
        });
    };

    const handleRangeInputChange = (field, subField, value) => {
        setFilters({
            ...filters,
            [field]: {
                ...filters[field],
                [subField]: value,
            },
        });
    };

    return (
        <div className="p-4 bg-gray-200 border px-5 py-5 mx-2 my-2">
            <h2 className="text-xl font-semibold mb-4">Filters</h2>

            <div className="mb-4">
                <label className="block mb-1">Search Term</label>
                <input
                    type="text"
                    className="w-full p-2 border rounded"
                    value={filters.searchTerm}
                    onChange={(e) =>
                        handleInputChange("searchTerm", e.target.value)
                    }
                />
            </div>

            <div className="mb-4">
                <label className="block mb-1">Sort By</label>
                <select
                    className="w-full p-2 border rounded"
                    value={filters.sortBy}
                    onChange={(e) =>
                        handleInputChange("sortBy", e.target.value)
                    }
                >
                    <option value="Views [High -> Low]">
                        Views [High -&gt; Low]
                    </option>

                    <option value="Views [Low -> High]">
                        Views [Low -&gt; High]
                    </option>

                    <option value="Likes [High -> Low]">
                        Likes [High -&gt; Low]
                    </option>

                    <option value="Likes [Low -> High]">
                        Likes [Low -&gt; High]
                    </option>

                    <option value="Dislikes [High -> Low]">
                        Dislikes [High -&gt; Low]
                    </option>

                    <option value="Dislikes [Low -> High]">
                        Dislikes [Low -&gt; High]
                    </option>

                    <option value="Like/Dislike Ratio [High -> Low]">
                        Like/Dislike Ratio [High -&gt; Low]
                    </option>

                    <option value="Like/Dislike Ratio [Low -> High]">
                        Like/Dislike Ratio [Low -&gt; High]
                    </option>

                    <option value="Comments [High -> Low]">
                        Comments [High -&gt; Low]
                    </option>

                    <option value="Comments [Low -> High]">
                        Comments [Low -&gt; High]
                    </option>

                    <option value="Date [High -> Low]">
                        Date [High -&gt; Low]
                    </option>

                    <option value="Date [Low -> High]">
                        Date [Low -&gt; High]
                    </option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block mb-1">Published Date</label>
                <div className="flex">
                    <input
                        type="text"
                        className="w-1/2 p-2 mr-2 border rounded"
                        value={filters.publishedDate.from}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "publishedDate",
                                "from",
                                e.target.value
                            )
                        }
                    />
                    <input
                        type="text"
                        className="w-1/2 p-2 border rounded"
                        value={filters.publishedDate.to}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "publishedDate",
                                "to",
                                e.target.value
                            )
                        }
                    />
                </div>
            </div>

            <div className="mb-4">
                <label className="block mb-1">Duration</label>
                <div className="flex">
                    <input
                        type="text"
                        className="w-1/2 p-2 mr-2 border rounded"
                        placeholder="From HH:MM:SS"
                        value={filters.duration.from}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "duration",
                                "from",
                                e.target.value
                            )
                        }
                    />
                    <input
                        type="text"
                        className="w-1/2 p-2 border rounded"
                        placeholder="To HH:MM:SS"
                        value={filters.duration.to}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "duration",
                                "to",
                                e.target.value
                            )
                        }
                    />
                </div>
            </div>

            <div className="mb-4">
                <label className="block mb-1">ViewCount</label>
                <div className="flex">
                    <input
                        type="text"
                        className="w-1/2 p-2 mr-2 border rounded"
                        value={filters.viewCount.from}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "viewCount",
                                "from",
                                e.target.value
                            )
                        }
                    />
                    <input
                        type="text"
                        className="w-1/2 p-2 border rounded"
                        value={filters.viewCount.to}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "viewCount",
                                "to",
                                e.target.value
                            )
                        }
                    />
                </div>
            </div>

            <div className="mb-4">
                <label className="block mb-1">LikeCount</label>
                <div className="flex">
                    <input
                        type="text"
                        className="w-1/2 p-2 mr-2 border rounded"
                        value={filters.likeCount.from}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "likeCount",
                                "from",
                                e.target.value
                            )
                        }
                    />
                    <input
                        type="text"
                        className="w-1/2 p-2 border rounded"
                        value={filters.likeCount.to}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "likeCount",
                                "to",
                                e.target.value
                            )
                        }
                    />
                </div>
            </div>

            <div className="mb-4">
                <label className="block mb-1">DislikeCount</label>
                <div className="flex">
                    <input
                        type="text"
                        className="w-1/2 p-2 mr-2 border rounded"
                        value={filters.dislikeCount.from}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "dislikeCount",
                                "from",
                                e.target.value
                            )
                        }
                    />
                    <input
                        type="text"
                        className="w-1/2 p-2 border rounded"
                        value={filters.dislikeCount.to}
                        onChange={(e) =>
                            handleRangeInputChange(
                                "dislikeCount",
                                "to",
                                e.target.value
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Filters;
